import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '4ea9110d-1b68-497c-a4f1-6e10b5aeba72',
  },
}

export default {
  name: {
    nb: 'Helseservicefag',
  },
  slug: 'helseservicefag',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-P3JFZ9R',
  },
  assets: {
    logo: '/themes/helseservicefag/images/logo.svg',
    favicon: '/themes/helseservicefag/favicon.ico',
  },
  dbok: {
    nb: [
      {
        title: 'Puls Helse og sykdom',
        link: 'https://reader.dbok.no/#/book/6194fe6742cc2c0012012ee8',
      },
      {
        title: 'Kommunikasjon og samhandling',
        link: 'https://reader.dbok.no/#/book/6194fe6742cc2c0012012ee8',
      },
      {
        title: 'Yrkesliv i helseservicefag',
        link: 'https://reader.dbok.no/#/book/62fb5a47a9fb7400139113d7',
      },
    ],
    nn: [
      {
        title: 'Helse og sjukdom',
        link: 'https://reader.dbok.no/#/book/61f3a9a669909d0012ae7514',
      },
      {
        title: 'Kommunikasjon og samhandling',
        link: 'https://reader.dbok.no/#/book/632973ef42aed200147b15a8',
      },
      {
        title: 'Yrkesliv i helseservicefag',
        link: 'https://reader.dbok.no/#/book/6336de011e144f00139fd177',
      },
    ],
  },
  styles: [
    '/themes/helseservicefag/css/helseservicefag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Nettressursen for vg2 helseservicefag</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Nettressursen for vg2 helseservicefag</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Yrkesoppgaver',
        to: '/articles/yrkesoppgaver',
        alias: '/articles/aktiviteter',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Hvordan har du det?',
        to: '/lookup/hvordan-har-du-det',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
    nn: [
      {
        title: 'Omgrep',
        to: '/concepts/begreper',
      },
      {
        title: 'Yrkesoppgåver',
        to: '/articles/yrkesoppgaver',
        alias: '/articles/aktiviteter',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Korleis har du det?',
        to: '/lookup/hvordan-har-du-det',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
  },
  concepts: {
    namespace: getDataByEnv('namespace', customDataByEnv),
    slug: 'begreper',
    langs: [
      {
        code: 'ar',
        label: {
          nb: 'Arabisk',
          nn: 'Arabisk',
        },
      },
      {
        code: 'fa-AF',
        label: {
          nb: 'Dari',
          nn: 'Dari',
        },
      },
      {
        code: 'ti',
        label: {
          nb: 'Tigrinja',
          nn: 'Tigrinja',
        },
      },
    ],
  },
}
